














































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Info from '@/components/common/Info.vue'
import CodesList from '@/components/codes/CodesList.vue'
import CodesArray from '@/components/codes/CodesArray.vue'
import Drawer from '@/components/common/Drawer.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import { CodesListItemType } from '@/types/codes'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import TabsCommon from '@/components/common/TabsCommon.vue'
import CodesCard from '@/components/mobile/codes/CodesCard.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import InputCommon from '@/components/common/InputCommon.vue'
import CodesFilters from '@/components/mobile/codes/CodesFilters.vue'
import { ReportsListItemType } from '@/types/reports'
import ReportsCard from '@/components/mobile/codes/ReportsCard.vue'
import CodesDetail from '@/components/codes/CodesDetail.vue'
import ReportsDetail from '@/components/codes/ReportsDetail.vue'
import ReportsFilters from '@/components/mobile/codes/ReportsFilters.vue'
import CreateCodes from '@/components/mobile/codes/CreateCodes.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import { Message } from 'element-ui'
import ShopInfo from '@/components/shops/ShopInfo.vue'
import { ShopsListItemType } from '@/types/shops'
import MembersDetail from '@/components/members/MembersDetail.vue'
import { MembersListItemType } from '@/types/members'
import PromotionDetailContent from '@/components/promotions/PromotionDetailContent.vue'
import { PromotionsItemListType } from '@/types/promotions'
import OrderReportsCard from '@/components/mobile/order_reports/OrderReportsCard.vue'
import OrderReportsTable from '@/components/order_reports/OrderReportsTable.vue'
import { CodeReportDetailType } from '@/types/orderReports'
import OrderReportsDetail from '@/components/order_reports/OrderReportsDetail.vue'
import CreateCodeReportDialog from '@/components/codesReports/CreateCodeReportDialog.vue'
import OrderReportsFilters from '@/components/mobile/order_reports/OrderReportsFilters.vue'
import { UserResponseType } from '@/types/user'

export type CodesFilterType = {
  STATE?: string
}

export type CodesListParamsType = {
  limit?: number
  offset?: number
  filter?: CodesFilterType
  order?: object
}

@Component({
  components: {
    OrderReportsFilters,
    CreateCodeReportDialog,
    OrderReportsDetail,
    OrderReportsTable,
    OrderReportsCard,
    DialogInfo,
    CreateCodes,
    ReportsFilters,
    ReportsDetail,
    CodesDetail,
    ReportsCard,
    CodesFilters,
    InputCommon,
    SelectCommon,
    CodesCard,
    TabsCommon,
    EllipseCommon,
    CodesArray,
    CodesList,
    Info,
    Drawer,
    FilterTags,
    ShopInfo,
    MembersDetail,
    PromotionDetailContent,
  },
})
export default class Codes extends Vue {
  loading = false
  loadingInfo = false

  showPromotionInfo = false
  showCodeInfo = false
  isCodeReportDetailShow = false
  selectTab = 'Списки кодов'

  activeTab: number = +this.$route.params.activeTab || 0

  filter = ''

  filters: TableFilterType[] = []

  statusColor = {
    NEW: 'default',
    ACTIVE: 'success',
    FINISHED: 'gray',
    BLOCKED: 'danger',
    MODERATION: 'warning',
    REQUIRED_CHANGE: 'purple',
  }

  promotionStatus = {
    NEW: 'Новая',
    ACTIVE: 'Активная',
    MODERATION: 'На модерации',
    FINISHED: 'Завершенная',
    BLOCKED: 'Заблокированная',
    REQUIRED_CHANGE: 'Требует изменений',
  }

  filterOptions = [
    {
      label: 'Все',
      value: '',
    },
    {
      label: 'Новые',
      value: 'NEW',
    },
    {
      label: 'Ожидают',
      value: 'REQUESTED',
    },
    {
      label: 'Активированные',
      value: 'ACTIVATED',
    },
    {
      label: 'Заблокированные',
      value: 'BLOCKED',
    },
  ]

  showCardFilters = false
  showFilters = false
  isAnimationEnd = false

  showShopInfo = false
  showMemberInfo = false

  showAddReportsForm = false
  addReportSuccess = false
  createReportsError = false

  order: TableOrderType | Record<string, any> = {}

  codesStatus = {
    NEW: 'Новый',
    REQUESTED: 'Ожидает',
    ACTIVATED: 'Активированный',
  }

  filterNames = {
    id: 'Id',
    ARTNUMBER: 'Артикул',
    PREFIX: 'Префикс',
    POINTS: 'Баллы',
    BONUS: 'Бонус',
    USER_LOGIN: 'Участник',
    CODE: 'Код',
    '>=DATE_ACTIVATE': 'Дата активации (от)',
    '<DATE_ACTIVATE': 'Дата активации (до)',
  }

  page = 1

  showReportsDetail = false

  showCreateCodes = false
  createCode = false
  createCodeError = false

  paramsCodesList: CodesListParamsType = {}

  get tabsList(): string[] {
    const array = ['Списки кодов', 'Массивы кодов']

    if (this.isAdmin || this.isVendor) {
      array.push('Отчеты по кодам')
    }
    return array
  }

  get errorCreateMessage(): string {
    return this.$store.getters['codes/errorCreateMessage']
  }

  get email(): UserResponseType {
    return this.$store.getters['user/user'].email
  }

  get isAdmin(): boolean {
    return this.$store.getters['user/user'].role === 'admin'
  }

  get isVendor(): boolean {
    return ['shopAdmin', 'vendorUser'].includes(
      this.$store.getters['user/user'].role
    )
  }

  get codesList(): CodesListItemType[] {
    return this.$store.getters['codes/codesList']
  }

  get codesTotal(): number {
    return this.$store.getters['codes/codesTotal']
  }

  get codesCount(): number {
    return this.$store.getters['codes/selectedCodes']
  }

  //TODO Не вся информация о коде
  get codesDetail(): CodesListItemType {
    return this.$store.getters['codes/codesDetail']
  }

  get codesExport(): TableExportType {
    return this.$store.getters['codes/exportCodes']
  }

  get promotionsDetail(): PromotionsItemListType {
    return this.$store.getters['promotions/promotionsDetail']
  }

  get reportsList(): ReportsListItemType[] {
    return this.$store.getters['reports/reportsList']
  }

  get reportsTotal(): number {
    return this.$store.getters['reports/reportsTotal']
  }

  get reportsDetail(): ReportsListItemType {
    return this.$store.getters['reports/reportsDetail']
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data) => {
      params[data.target] = data.data
    })

    if (this.filter) {
      if (this.filter === 'BLOCKED') {
        params['IS_ACTIVE'] = false
      } else {
        params['STATE'] = this.filter
      }
    }

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get shopsDetail(): ShopsListItemType {
    return this.$store.getters['shops/shopsDetail']
  }

  get membersDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get codesTypeCount() {
    const response = this.$store.getters['codes/codesCount']
    let result = {
      NEW: 0,
      ACTIVATED: 0,
      REQUESTED: 0,
      BLOCKED: 0,
    }

    response.forEach((value) => {
      result[value.status] = value.count
    })

    return result
  }

  get codesReportList(): ReportsListItemType[] {
    return this.$store.getters['codes/codesReportList']
  }

  get detailReport(): CodeReportDetailType {
    return this.$store.getters['codes/detailReport']
  }

  get orderReportsExport(): TableExportType {
    return this.$store.getters['orderReports/exportOrderReports']
  }

  setShowedCount(total: string): string | number {
    if (total) {
      return 10 * this.page > parseInt(total) ? total : 10 * this.page
    } else {
      return 0
    }
  }

  async handleDetailReportShow(id: number): Promise<void> {
    this.loadingInfo = true
    this.showMemberInfo = false
    this.isCodeReportDetailShow = true

    await this.$store.dispatch('codes/getCodeReportDetail', id.toString())

    this.loadingInfo = false
  }

  async handleReportExport(): Promise<void> {
    await this.$store.dispatch('orderReports/getExportOrderReports', {
      filter: this.tableFilters,
      order: this.tableOrder,
    })

    if (
      this.orderReportsExport.type === 'error' ||
      this.orderReportsExport.type === 'queue'
    ) {
      Message.error(this.orderReportsExport.text)
    } else {
      const link = document.createElement('a')

      link.setAttribute(
        'href',
        process.env.VUE_APP_BASE_URI + this.orderReportsExport.link
      )
      link.setAttribute('download', this.orderReportsExport.name)
      link.click()
      link.remove()
    }
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  async handleShowCodeInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showCodeInfo = true
    await this.$store.dispatch('codes/getCodesDetail', id)
    this.loadingInfo = false

    setTimeout(() => {
      this.isAnimationEnd = true
    }, 200)
  }

  async handleShopInfoOpen(id: CodesListItemType['SHOP_ID']): Promise<void> {
    this.loadingInfo = true
    this.showShopInfo = true

    await this.$store.dispatch('shops/getShopsDetail', id)

    this.loadingInfo = false
  }

  async handleShowMemberInfo(id: CodesListItemType['ID']) {
    this.loadingInfo = true

    this.showShopInfo = false
    this.showCodeInfo = false
    this.isCodeReportDetailShow = false

    this.showMemberInfo = true

    await this.$store.dispatch('members/getMembersDetail', id)

    this.loadingInfo = false
  }

  async handleBlockCode(): Promise<void> {
    this.loadingInfo = true
    await this.$store.dispatch('codes/blockCodes', this.codesDetail.id)

    this.loading = true
    await this.$store.dispatch('codes/getCodesDetail', this.codesDetail.id)

    await this.$store.dispatch('notifications/getNotificationsCount')

    await this.$store.dispatch('codes/getCodesList', this.paramsCodesList)
    await this.$store.dispatch('codes/getCodesCount')

    this.loadingInfo = false
    this.loading = false
  }

  lazyDownload(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.codesExport.link
    )
    link.setAttribute('download', this.codesExport.name)
    link.click()
    link.remove()
  }

  async handleExportCodes(): Promise<void> {
    if (!this.codesList.length) return

    await this.$store.dispatch('codes/getExportCodes', {
      filter: this.tableFilters,
      order: this.tableOrder,
    })

    if (
      this.codesExport.type === 'error' ||
      this.codesExport.type === 'queue'
    ) {
      Message.error(this.codesExport.text || 'Ошибка')
    } else {
      this.lazyDownload()
    }
  }

  async handleTabClick(data: { id: number; tab: string }): Promise<void> {
    this.filters = []
    this.page = 1
    this.selectTab = data.tab
    this.order = {}
    this.filter = ''
    this.$router.push({ name: 'Codes', query: { tab: data.tab } })
  }

  handleCreateCodes(): void {
    this.$router.push({ name: 'CreateCodes' })
  }

  handleShowCreateCodes(): void {
    this.showCreateCodes = !this.showCreateCodes
  }

  async handleChangePage(_params?: CodesListParamsType): Promise<void> {
    const tabs = document.querySelector('.codes__tabs') as HTMLElement

    tabs?.scrollIntoView({ behavior: 'smooth' })

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = _params?.filter || this.tableFilters
    params.order = _params?.order || this.tableOrder
    params.offset = _params?.offset || (this.page - 1) * 10

    this.loading = true

    await this.$store.dispatch('codes/getCodesList', params)

    await this.$store.dispatch('codes/getCodesCount')

    switch (this.selectTab) {
      case 'Массивы кодов':
        await this.$store.dispatch('reports/getReportsList', params)
        break
      case 'Отчеты по кодам':
        await this.$store.dispatch('codes/getCodeReportList', params)
        break
    }

    this.loading = false
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data
    this.handleChangePage()
  }

  makeFiltersAction(filters: TableFilterType['dateFilters']): void {
    filters?.forEach((filter) => {
      const foundFilterIndex = this.filters.findIndex(
        (oldFilter) => filter.target === oldFilter.target
      )

      if (foundFilterIndex === -1) {
        this.filters.push({
          data: filter.data,
          target: filter.target,
          name: this.filterNames[filter.target],
        })
      } else {
        if (filter.data === '') {
          this.handleDeleteFilter(foundFilterIndex)
        }

        this.filters.forEach((item) => {
          if (item.target === filter.target) {
            this.handleDeleteFilter(foundFilterIndex)

            this.filters.push({
              data: filter.data,
              target: filter.target,
              name: this.filterNames[filter.target],
            })
          }
        })
      }
    })

    this.isAnimationEnd = true
  }

  async handleMemberInfoShow(value: string) {
    this.showPromotionInfo = false

    this.loadingInfo = true

    this.showMemberInfo = true

    await this.$store.dispatch('members/getMembersDetail', value)

    this.loadingInfo = false
  }

  async handleShopInfoShow(value: string) {
    this.showPromotionInfo = false

    this.loadingInfo = true

    this.showShopInfo = true

    await this.$store.dispatch('shops/getShopsDetailByRemoteId', value)

    this.loadingInfo = false
  }

  handleAddTableFilter(data: TableFilterType): void {
    if (data?.dateFilters?.length) {
      this.makeFiltersAction(data.dateFilters)
    } else {
      this.makeFiltersAction([data])
    }
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  handleShowCardsFilters(): void {
    this.showCardFilters = !this.showCardFilters
  }

  async handleShowReportsDetail(id: number): Promise<void> {
    this.loadingInfo = true
    this.showReportsDetail = true

    this.$store.dispatch('reports/getReportsDetail', id)

    this.loadingInfo = false
  }

  handleCloseShopInfo(): void {
    this.showShopInfo = false
  }

  handleSelectFilter(option: string): void {
    this.filter = option
    this.handleShowFilters()
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowCardsFilters()
    data.forEach((value) => {
      this.handleAddTableFilter(value)
    })
  }

  openDialogCodeError(): void {
    this.createCodeError = !this.createCodeError
  }

  openDialogCode(): void {
    this.createCode = !this.createCode
  }

  handleAddReportForm(): void {
    this.showAddReportsForm = !this.showAddReportsForm
  }

  handleShowSuccess(): void {
    this.addReportSuccess = !this.addReportSuccess
  }

  handleReportsError(): void {
    this.createReportsError = !this.createReportsError
  }

  async handlePromotionInfoOpen(id: string) {
    this.loadingInfo = true
    await this.$store.dispatch('promotions/getPromotionsDetail', id)

    this.showPromotionInfo = true

    this.loadingInfo = false
  }

  @Watch('filters')
  onFiltersChange(): void {
    this.handleChangePage()
  }

  @Watch('filter')
  onFilterChange(): void {
    if (this.selectTab === 'Отчеты по кодам') return

    this.handleChangePage()
  }

  @Watch('page')
  onPageChange(): void {
    this.handleChangePage()
  }

  async mounted(): Promise<void> {
    this.selectTab = this.$route.query?.tab?.toString() || 'Списки кодов'
    this.activeTab = this.tabsList.findIndex((tab) => tab === this.selectTab)
    await this.handleChangePage()
  }
}
